import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";

import CaretDown from "@/inline-assets/caret-down.svg";
import CaretUp from "@/inline-assets/caret-up.svg";
import ErrorComponent from "@/shared/ErrorComponent.jsx";
import InfiniteTable, { SORT_ASC, SORT_DESC } from "@/shared/InfiniteTable.jsx";
import {
  FlexContainer,
  HeaderLabel,
  HeaderWrapper,
  SortIconWrapper,
} from "@/shared/InfiniteTable.style.jsx";

const ErrorWrapper = styled("div")`
  height: ${(props) => props.$height ?? "28rem"};
`;

const cssTableHeader = () => css`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: var(--sp-3);
  letter-spacing: -0.009em;
  color: var(--shade1);
  background: var(--shade7);
`;

const sort = (arr, sortBy, sortDirection, tiebreaker) => {
  if (!arr) return [];

  if (typeof arr[0]?.[sortBy] !== "number") return arr;

  const sorted = arr.sort(
    (a, b) => a?.[sortBy] - b?.[sortBy] || a?.[tiebreaker] - b?.[tiebreaker],
  );

  return sortDirection === SORT_ASC ? sorted : sorted.reverse();
};

const defaultOptions = {
  headerHeight: 30,
  rowHeight: 48,
};
const useChampionsTable = ({
  tableData,
  cols,
  colRenderer,
  isLoaded,
  options,
  maxResults,
  topOffset,
  rowClassName,
  headerClassName,
  errorHeight,
}) => {
  const { t } = useTranslation();
  const rowHeight = options?.rowHeight || defaultOptions.rowHeight;
  const headerHeight = options?.headerHeight || defaultOptions.headerHeight;
  const [sortParams, setSortParams] = useState({
    sortBy: options.sortBy,
    sortDirection: options.sortDirection || SORT_DESC,
  });

  const sortedData = useMemo(
    () =>
      sort(
        [...tableData],
        sortParams.sortBy,
        sortParams.sortDirection,
        options.sortByTieBreak,
      ).slice(0, maxResults),
    [
      tableData,
      sortParams.sortBy,
      sortParams.sortDirection,
      options.sortByTieBreak,
      maxResults,
    ],
  );

  // for ssr styling, we are using functional css.
  // so this should be called here.
  const styledCols = useMemo(
    () =>
      cols?.map((c) => ({
        ...c,
        className:
          c.className && typeof c.className === "function"
            ? c.className()
            : c.className,
      })),
    [cols],
  );

  const styledCss = useMemo(
    () => ({
      header: headerClassName || cssTableHeader(),
      row: rowClassName,
    }),
    [headerClassName, rowClassName],
  );

  const headerRenderer = useCallback(
    ({ dataKey, sortable, sortBy, sortDirection, i18n }) => {
      const isActive = sortable && dataKey === sortBy;
      const Caret = sortDirection === SORT_DESC ? CaretDown : CaretUp;

      return (
        <HeaderWrapper>
          <HeaderLabel
            active={isActive ? 1 : 0}
            className={sortable && "sortable"}
          >
            {t(...i18n)}
          </HeaderLabel>
          {isActive && <SortIconWrapper>{Caret && <Caret />}</SortIconWrapper>}
        </HeaderWrapper>
      );
    },
    [t],
  );
  const emptyRenderer = useCallback(() => {
    if (!isLoaded) return null;
    const emptyMessage = options?.emptyMessage
      ? t(...options.emptyMessage)
      : t("lol:notFound.championData", "No champion data found.");
    return (
      <ErrorWrapper $height={errorHeight}>
        <ErrorComponent description={emptyMessage} />
      </ErrorWrapper>
    );
  }, [t, isLoaded, options.emptyMessage, errorHeight]);

  const View = useMemo(
    () => (
      <FlexContainer $topOffset={topOffset}>
        <InfiniteTable
          data={sortedData}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          sortBy={sortParams.sortBy}
          sortDirection={sortParams.sortDirection}
          sort={setSortParams}
          rowCount={sortedData.length}
          rowGetter={({ index }) => sortedData[index]}
          headerClassName={styledCss.header}
          rowClassName={styledCss.row}
          colRenderer={colRenderer}
          HeaderRenderer={headerRenderer}
          emptyRenderer={emptyRenderer}
          cols={styledCols}
        />
      </FlexContainer>
    ),
    [
      sortedData,
      sortParams,
      colRenderer,
      headerRenderer,
      emptyRenderer,
      styledCols,
      styledCss,
      headerHeight,
      rowHeight,
      topOffset,
    ],
  );

  return {
    TableView: View,
  };
};

export default useChampionsTable;
