import React from "react";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { /*appURLs,*/ hardCodeURLs } from "@/app/constants.mjs";
// import ChampionComingSoon from "@/game-lol/components/ChampionComingSoon.jsx";

function NewChampionComingSoon({ _championName }) {
  return (
    <>
      {/* <ChampionComingSoon
        title={[
          "lol:newChampion.title",
          "New Champion - {{championName}}",
          { championName },
        ]}
        subtitle={[
          "lol:newChampion.subtitle",
          "Come back soon to get the latest on League of Legends newest champion {{championName}} including Abilities, Stats, Builds, and more!",
          { championName },
        ]}
        className={ComingSoonContainer()}
      >
        <img
          src={`${appURLs.CDN}/blitz/lol/misc/aurora-bg.webp`}
          width="1200"
          height="727"
          className="bg-image"
        />
        <img
          src={`${appURLs.CDN}/blitz/lol/misc/aurora-pose-1.webp`}
          width="584"
          height="700"
          className="pose-image"
        />
        <video
          autoPlay
          loop
          muted
          src={`${appURLs.CDN_VIDEOS}/lol/misc/looping_snow.webm`}
          width="1200"
          height="727"
          className="bg-video"
        />
      </ChampionComingSoon> */}
      <Card padding="0" className={VideoContainer()}>
        <iframe
          width="560"
          height="315"
          src={`${hardCodeURLs.YOUTUBE_EMBED}/pqQ00QqJEys`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </Card>
    </>
  );
}

export default NewChampionComingSoon;

// const ComingSoonContainer = () => css`
//   .bg-image,
//   .bg-video {
//     position: absolute;
//     inset: 0;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     pointer-events: none;
//   }
//   .bg-image {
//     -webkit-mask-image: linear-gradient(
//       to top,
//       hsl(0deg 0% 0% / 15%),
//       black 50%
//     );
//   }
//   .pose-image {
//     position: absolute;
//     bottom: 0;
//     right: 2%;
//     height: 94%;
//     width: auto;
//     transform: scaleX(-1);
//     filter: drop-shadow(0px 0px 33px black);
//   }

//   @container champion-soon (inline-size <= 600px) {
//     .bg-video {
//       display: none;
//     }
//     .pose-image {
//       right: 50%;
//       translate: 50% 0;
//     }
//   }
// `;

const VideoContainer = () => css`
  iframe {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    border: none;
  }
`;
