import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { appURLs } from "@/app/constants.mjs";

const ComingSoonSplash = styled("div")`
  container-type: inline-size;
  container-name: arena-splash;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);
    padding: var(--sp-10);
    padding-top: 25rem;
    background: var(--shade10);
    border-radius: var(--br);
    text-shadow: 0 2px 7px var(--shade9);

    @container arena-splash (max-width: 600px) {
      padding: var(--sp-6);
      padding-top: 20rem;
    }

    > * {
      position: relative;
    }

    .title,
    .subtitle,
    hr {
      position: relative;
      max-width: 60ch;
    }
    hr {
      width: 60px;
      height: 4px;
      margin: 0;
      border: none;
      background: var(--primary);
    }
    img {
      position: absolute;
    }
    .bg-container {
      position: absolute;
      inset: 0;
      border-radius: var(--br);
      overflow: hidden;
    }
    .bg {
      --blur: 5px;
      bottom: calc(var(--blur) * -1);
      left: calc(var(--blur) * -1);
      height: 120%;
      width: auto;
      opacity: 25%;
      filter: blur(var(--blur));
      -webkit-mask-image: linear-gradient(
        to bottom,
        hsl(0deg 0% 0% / 70%) 0%,
        black 70%
      );
    }

    @keyframes floatWood {
      0%,
      100% {
        translate: 0 -4%;
        rotate: -20deg;
      }
      50% {
        translate: 0 4%;
        rotate: -17deg;
      }
    }
    @keyframes floatBronze {
      0%,
      100% {
        translate: 0 6%;
        rotate: 30deg;
      }
      50% {
        translate: 0 -6%;
        rotate: 25deg;
      }
    }
    @keyframes floatSilver {
      0%,
      100% {
        translate: 0 -5%;
      }
      50% {
        translate: 0 3%;
      }
    }
    @keyframes floatGold {
      0%,
      100% {
        translate: 0 -7%;
      }
      50% {
        translate: 0 7%;
      }
    }
    @keyframes floatGladiator {
      0%,
      100% {
        translate: 0 6%;
      }
      50% {
        translate: 0 -6%;
      }
    }

    .medallion {
      filter: drop-shadow(2px 4px 10px hsla(0deg 0% 0% / 0.5));
    }
    .wood {
      top: 2%;
      left: -2%;
      rotate: -20deg;
      animation: 7s floatWood ease-in-out forwards infinite;
    }
    .bronze {
      bottom: 53%;
      right: 16%;
      rotate: 30deg;
      animation: 10s floatBronze ease-in-out forwards infinite;

      @container arena-splash (max-width: 600px) {
        width: 80px;
        bottom: 53%;
        right: 9%;
      }
    }
    .silver {
      top: -2%;
      right: 1%;
      rotate: 4deg;
      animation: 6s floatSilver ease-in-out forwards infinite;

      @container arena-splash (max-width: 600px) {
        width: 100px;
        bottom: 80%;
      }
    }
    .gold {
      top: 26%;
      left: 13%;
      rotate: 16deg;
      animation: 9s floatGold ease-in-out forwards infinite;

      @container arena-splash (max-width: 600px) {
        width: 120px;
        bottom: 47%;
      }
    }
    .gladiator {
      top: 4%;
      left: 37%;
      rotate: 30deg;
      animation: 10s floatGladiator ease-in-out forwards infinite;

      @container arena-splash (max-width: 600px) {
        width: 150px;
        bottom: 58%;
      }
    }
  }
`;

function ArenaComingSoon() {
  const { t } = useTranslation();

  return (
    <ComingSoonSplash>
      <div className="content">
        <div className="bg-container">
          <img
            className="bg"
            src={`${appURLs.CDN}//blitz/lol/arena/bg-2.webp`}
            width="1534"
            height="849"
          />
        </div>
        <img
          className="wood medallion"
          src={`${appURLs.CDN}//blitz/lol/arena/wood-medallion.webp`}
          width="130"
          height="130"
        />
        <img
          className="bronze medallion"
          src={`${appURLs.CDN}//blitz/lol/arena/bronze-medallion.webp`}
          width="100"
          height="100"
        />
        <img
          className="silver medallion"
          src={`${appURLs.CDN}//blitz/lol/arena/silver-medallion.webp`}
          width="145"
          height="145"
        />
        <img
          className="gold medallion"
          src={`${appURLs.CDN}//blitz/lol/arena/gold-medallion.webp`}
          width="160"
          height="160"
        />
        <img
          className="gladiator medallion"
          src={`${appURLs.CDN}//blitz/lol/arena/gladiator-medallion.webp`}
          width="280"
          height="280"
        />
        <h1 className="type-h4 title">
          {t("lol:arena.statsComingSoon", "Arena Stats Coming Soon!")}
        </h1>
        <hr />
        <p className="type-body1 subtitle">
          {t(
            "lol:arena.comeBackSoonForStats",
            "Our little worker poros are grinding away collecting Arena Stats. Come back soon to find out who you should be playing to secure yourself Gladiator!",
          )}
        </p>
        <p className="type-caption" style={{ color: "var(--shade1)" }}>
          {t(
            "lol:arena.placeholderCopy",
            "Prepare for the intense battles in League of Legends' newest mode - Arena! Dive into unique 2v2v2v2 match-ups, strategically selecting Augments, enduring surprise cameos, and climbing the Ranked ladder from Wood to Gladiator. Our upcoming stats page will keep track of your epic journey and victories!",
          )}
        </p>
      </div>
    </ComingSoonSplash>
  );
}

export default ArenaComingSoon;
