import React from "react";

import ChampionBuilds from "@/game-lol/components/ChampionBuilds.jsx";
import ChampionInsights from "@/game-lol/components/ChampionInsights.jsx";
import NexusBlitzComingSoon from "@/game-lol/components/NexusBlitzComingSoon.jsx";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import { QUEUE_SYMBOL_TO_OBJECT } from "@/game-lol/utils/symbol-queue.mjs";

const URF = QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.urf];

function ChampionURF({ champion, filters }) {
  if (URF.comingSoon) {
    return <NexusBlitzComingSoon />;
  }

  return (
    <>
      <ChampionBuilds
        champion={champion}
        championId={champion?.id}
        queue={URF.gql}
        region={filters.region}
      />
      <ChampionInsights championId={champion?.id} champion={champion} />
    </>
  );
}

export default ChampionURF;
