import React from "react";
import { styled } from "goober";

const StatValue = styled("div")`
  display: flex;
  align-items: center;
`;

const Delta = styled("span")`
  margin-left: var(--sp-1_5);
`;
const Subtitle = styled("span")`
  display: block;
  color: var(--shade1);
`;

const StatDelta = ({
  value,
  className,
  suppressHydrationWarning,
}: React.HTMLAttributes<HTMLSpanElement> & { value: string }) => (
  <Delta
    className={`type-caption--bold ${className}`}
    style={{ color: parseFloat(value) > 0 ? "var(--turq)" : "var(--red)" }}
    suppressHydrationWarning={suppressHydrationWarning}
  >
    ({parseFloat(value) > 0 && "+"}
    {value})
  </Delta>
);

const StatValueLabel = styled("div")`
  font-size: 0.875rem;
  color: var(--shade0);
  margin-left: 6px;
  margin-right: 2px;
`;

export const StatInfo = ({ value, delta, caption, valueLabel }) => (
  <>
    <StatValue>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <p className="type-page-header" suppressHydrationWarning>
          {value}
        </p>
        {valueLabel && (
          <StatValueLabel suppressHydrationWarning>{valueLabel}</StatValueLabel>
        )}
      </div>
      {delta && <StatDelta value={delta} suppressHydrationWarning />}
    </StatValue>
    <Subtitle className="type-caption--semi">{caption}</Subtitle>
  </>
);

export const GraphLegendItem = styled("div")<{ active?: boolean }>`
  color: ${(props) => (props.active ? "var(--turq)" : "var(--shade2)")};
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: var(--sp-4);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: ${(props) => (props.active ? "var(--turq)" : "var(--shade2)")};
    height: 8px;
    width: 8px;
    left: 0;
    top: 5px;
  }
`;
