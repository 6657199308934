import React from "react";
import { Card } from "clutch/src/Card/Card.jsx";

import { Container } from "@/game-lol/components/ChampionAbilityVideos.style.jsx";
import { SKILL_HOTKEYS } from "@/game-lol/constants/constants.mjs";
import {
  abilityInfoMarkup,
  tooltipContent,
} from "@/game-lol/utils/champion-ability-tooltip.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { getAbilityVideo } from "@/game-lol/utils/util.mjs";
import { classNames } from "@/util/class-names.mjs";
import { useQuery } from "@/util/router-hooks.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";

const HOTKEYS = ["P", ...SKILL_HOTKEYS];

function ChampionAbilityVideos({ champion }) {
  const [selectedHotkey, setSelectedHotkey] = useQuery("ability", "");

  if (!champion.spells) {
    return <Card loading style={{ height: 670 }} />;
  }

  const videoSrc = getAbilityVideo(champion.key, selectedHotkey || HOTKEYS[0]);

  const selectedHotkeyIndex = selectedHotkey
    ? HOTKEYS.indexOf(selectedHotkey)
    : 0;
  const ability = champion.spells?.[selectedHotkeyIndex];
  const abilityMarkup =
    ability &&
    abilityInfoMarkup(
      ability,
      tooltipContent(
        ability,
        champion.key,
        selectedHotkey,
        selectedHotkeyIndex === 0 && ability,
      ),
      {
        abilityName: "type-large-title--bold",
        text: "type-subtitle",
        values: "type-subtitle--semi",
      },
    );

  return (
    <Card className={Container()} padding="0">
      <div className="video-container">
        <video
          loop
          autoPlay
          muted
          playsInline
          src={videoSrc}
          width="1016"
          height="571"
        ></video>
      </div>
      <div className="ability-hotkeys">
        {HOTKEYS.map((hotkey, abilityIndex) => {
          const ability = champion.spells?.[abilityIndex];
          if (!ability) return null;
          const passive = abilityIndex === 0 && champion.spells?.[0];
          const displayImg = passive
            ? Static.getChampionPassiveImageById(passive.image.full)
            : Static.getChampionSpellImageById(ability.id);
          const isSelected =
            selectedHotkey === hotkey || (!selectedHotkey && passive);

          return (
            <button
              key={hotkey}
              {...classNames("hotkey-button", isSelected && "selected-hotkey")}
              onClick={() => {
                setSelectedHotkey(hotkey);
                sendInteractionEvent("champion-ability-video");
              }}
            >
              <img src={displayImg} alt={ability.name} width="40" height="40" />
              <span className="hotkey type-subtitle--bold">{hotkey}</span>
            </button>
          );
        })}
      </div>
      <div
        className="ability-description"
        dangerouslySetInnerHTML={{ __html: abilityMarkup }}
      />
    </Card>
  );
}

export default ChampionAbilityVideos;
