import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import ItemImg from "@/game-lol/components/ItemImg.jsx";
import { POTIONS, TRINKETS } from "@/game-lol/constants/builds-constants.mjs";
import { getStaticData } from "@/game-lol/utils/util.mjs";
import { formatOrdinalNumber } from "@/util/i18n-helper.mjs";

const BuildOrder = ({
  size = 52,
  starting = [],
  items = [],
  patch,
  noTag,
  noComponents,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const itemsStaticData = getStaticData("items");
  const buildComponents = [];

  if (!itemsStaticData) return null;

  const buildList = items ? items.map((item) => itemsStaticData[item]) : [];
  buildList.forEach((item) => {
    const itemComponents = itemsStaticData[item?.id]?.from || [];
    buildComponents.push(...itemComponents);
  });

  const itemBuildItems = items
    ? items.map((item) => itemsStaticData[item])
    : [];

  const start = starting.filter((item) => !POTIONS[item] && !TRINKETS[item]);

  return (
    <Container cols={7} data-no-components={!!noComponents}>
      {start[1] ? (
        <Item>
          <div className="flex gap-sp-1">
            {start.map((item) => (
              <Img
                key={item}
                itemId={item}
                patch={patch}
                size={size}
                borderRadius={5}
                className="lg"
              />
            ))}
          </div>
          {!noTag && (
            <p className="type-caption--bold tag">
              {t("lol:championData.starting", "Starting")}
            </p>
          )}
        </Item>
      ) : start[0] ? (
        <Item>
          <Img
            itemId={start[0]}
            patch={patch}
            size={size}
            borderRadius={5}
            className="lg"
          />
          {!noTag && (
            <p className="type-caption--bold tag">
              {t("lol:championData.starting", "Starting")}
            </p>
          )}
        </Item>
      ) : null}
      {itemBuildItems.filter(Boolean).map((item, i) => {
        return (
          <Item key={`${item.id}_${i}`}>
            <Img
              itemId={item.id}
              patch={patch}
              size={size}
              borderRadius={5}
              className="lg"
            />
            {!noComponents && item.from ? (
              <List cols={item.from.length} sublist="true">
                {item.from.map((subItem, i) => (
                  <Img
                    key={`${subItem}_${i}`}
                    itemId={subItem}
                    patch={patch}
                    size={size}
                    borderRadius={5}
                    className="sm"
                  />
                ))}
              </List>
            ) : null}
            {!noTag && (
              <div
                className={`tag item-${formatOrdinalNumber("en-US", i + 1)}`}
              >
                <p className="type-caption--bold">
                  {formatOrdinalNumber(language, i + 1)}
                </p>
              </div>
            )}
          </Item>
        );
      })}
    </Container>
  );
};

export default BuildOrder;

const List = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: var(--sp-3);
  justify-content: ${(props) =>
    props.sublist === "true" ? "center" : "initial"};
  margin: 0 -0.25rem;
`;

const Container = styled(List)`
  padding-top: 0.25rem;
`;

const Item = styled("div")`
  position: relative;
  background: var(--card-surface);
  margin: var(--sp-0_5);
  border-radius: var(--br);

  .tag {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -40%);
    background: var(--card-surface);
    padding: 0 0.375rem;
    margin: 0;
    border-radius: var(--br-sm);
    color: var(--shade1);

    &.item-1st,
    &.item-2nd,
    &.item-3rd {
      color: var(--shade0);
    }
  }
`;

const Img = styled(ItemImg)`
  display: block;
  max-width: 100%;
  height: auto;
  background: var(--shade10);

  [data-no-components="true"] & {
    &.lg {
      margin: 0;
    }
  }

  &.lg {
    width: var(--sp-13);
    width: ${(props) => props.size}px;
    height: var(--sp-13);
    height: ${(props) => props.size}px;
    max-width: var(--sp-13);
    max-width: ${(props) => props.size}px;
    max-height: var(--sp-13);
    max-height: ${(props) => props.size}px;
    margin: 0 auto 0.25rem;
  }
  &.sm {
    width: var(--sp-6);
    width: ${(props) => Math.round(props.size / 2)}px;
    height: var(--sp-6);
    height: ${(props) => Math.round(props.size / 2)}px;
    max-width: var(--sp-6);
    max-height: ${(props) => Math.round(props.size / 2)}px;
    margin: 0 0.175rem;
    opacity: 0.6;
    filter: saturate(0.8);

    &:hover {
      opacity: 1;
      filter: saturate(1);
    }
  }
`;
