import React from "react";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import ProBuildSummary from "@/game-lol/components/ProBuildSummary.jsx";
import ProMatchesList from "@/game-lol/components/ProMatchesList.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ChampionProbuilds = ({
  champion,
  matchupChampion,
  filters,
  victoryOnly,
}) => {
  const {
    lol: { championProMatches },
  } = useSnapshot(readState);

  const championId = champion?.id;
  const matchupChampionId = matchupChampion?.id;

  const probuilds = matchupChampionId
    ? championProMatches?.[championId]?.[filters.role]?.[matchupChampionId]
    : championProMatches?.[championId]?.[filters.role];
  const matches = probuilds?.probuildMatches;
  const filteredMatches = victoryOnly
    ? matches?.filter((e) => e.win === true)
    : matches;

  return (
    <Card style={{ display: "grid", gap: "var(--sp-4)" }}>
      {filteredMatches?.length ? (
        <ProBuildSummary matches={filteredMatches} />
      ) : null}
      <ProMatchesList matches={filteredMatches || []} />
    </Card>
  );
};

export default ChampionProbuilds;
