import { css } from "goober";

export const Container = () => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sp-6);

  @container content-card (inline-size <= 820px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--sp-4);
  }

  .stat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--sp-3);
    flex: 1;
    padding: var(--sp-6);
    background: var(--shade7);
    border-radius: var(--br);
    box-shadow: inset 0 0 0 1px var(--card-inset-border);
  }
  .stat-title {
    display: block;
    color: var(--shade1);
  }
`;
