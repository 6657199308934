import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card";

import { appURLs } from "@/app/constants.mjs";

const ComingSoonSplash = styled("div")`
  container-type: inline-size;
  container-name: arena-splash;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);
    padding: var(--sp-10);
    padding-bottom: 17rem;
    background: var(--shade10);
    border-radius: var(--br-lg);
    text-shadow: 0 2px 7px var(--shade9);

    @container arena-splash (max-width: 600px) {
      padding: var(--sp-6);
      padding-bottom: 13rem;
    }

    > * {
      position: relative;
    }

    .title,
    .subtitle,
    .subsubtitle,
    hr {
      position: relative;
      max-width: 40rem;
    }
    hr {
      width: 60px;
      height: 4px;
      margin: 0;
      border: none;
      background: var(--game-accent-color);
    }
    img {
      position: absolute;
      pointer-events: none;
    }
    .bg-container {
      position: absolute;
      inset: 0;
      border-radius: var(--br-lg);
      overflow: hidden;
    }
    .bg {
      --blur: 5px;
      bottom: calc(var(--blur) * -1);
      left: calc(var(--blur) * -1);
      height: 120%;
      width: auto;
      opacity: 25%;
      filter: blur(var(--blur));
      -webkit-mask-image: linear-gradient(
        to bottom,
        hsl(0deg 0% 0% / 70%) 0%,
        black 70%
      );
    }
  }
`;

function ComingSoon() {
  const { t } = useTranslation();

  return (
    <Card padding="0">
      <ComingSoonSplash>
        <div className="content">
          <div className="bg-container">
            <img
              className="bg"
              src={`${appURLs.CDN}/blitz/lol/nexus-blitz/nexus-blitz-splash.webp`}
              width="1534"
              height="849"
            />
          </div>
          <h1 className="type-h4 title">
            {t(
              "lol:nexusBlitz.statsComingSoon",
              "Nexus Blitz Stats Coming Soon!",
            )}
          </h1>
          <hr />
          <p className="type-body1 subtitle">
            {t(
              "lol:nexusBlitz.comeBackSoonForStats",
              "Our little worker poros are grinding away collecting Nexus Blitz Stats. Come back soon to find out who you should be playing!",
            )}
          </p>
          <p
            className="type-caption subsubtitle"
            style={{ color: "var(--shade1)", textWrap: "pretty" }}
          >
            {t(
              "lol:nexusBlitz.placeholderCopy",
              "Prepare for the intense battles in League of Legends' rotating game mode - Nexus Blitz! Our upcoming stats page will keep track of your epic journey and victories!",
            )}
          </p>
        </div>
      </ComingSoonSplash>
    </Card>
  );
}

export default ComingSoon;
