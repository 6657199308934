import { styled } from "goober";

import AbilityImg from "@/game-lol/components/AbilityImg.jsx";
import ItemImg from "@/game-lol/components/ItemImg.jsx";
import LolColor from "@/game-lol/constants/colors.mjs";

export const ListTitle = styled("span")`
  display: block;
`;
export const List = styled("ul")`
  list-style: circle;
  padding-inline-start: 2rem;
`;

export const StyledAbilityImg = styled(AbilityImg)`
  && {
    display: inline-block;
  }
  margin-left: var(--sp-1);
  margin-right: var(--sp-1);
  margin-bottom: calc(var(--sp-1) * -1);
  width: var(--sp-5);
  height: var(--sp-5);
  border-radius: var(--br-sm);
`;

export const StyledItemImg = styled(ItemImg)`
  && {
    display: inline-block;
  }
  margin-left: var(--sp-1);
  margin-right: var(--sp-1);
  margin-bottom: calc(var(--sp-1) * -1);
  width: var(--sp-5);
  height: var(--sp-5);
  border-radius: var(--br-sm);
`;

export const AbilityBox = styled("div")`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.875rem;
  height: 0.875rem;
  background: var(--shade10);
  right: 0.25rem;
  bottom: 0;
  border-bottom-right-radius: 3px;

  &.box-skill--1 {
    color: ${LolColor.abilities[1]};
  }
  &.box-skill--2 {
    color: ${LolColor.abilities[2]};
  }
  &.box-skill--3 {
    color: ${LolColor.abilities[3]};
  }
  &.box-skill--4 {
    color: ${LolColor.abilities[4]};
  }

  .ability-text {
    font-weight: 700;
  }
`;

export const ItemContainer = styled("li")`
  padding: var(--sp-0_5) 0;
`;

export const Inline = styled("div")`
  display: inline;
`;

export const RelativeInline = styled("div")`
  display: inline;
  position: relative;
`;
