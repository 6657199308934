import { css } from "goober";

export const Container = () => css`
  --inset: var(--sp-8);
  position: relative;

  @container content-card (inline-size <= 600px) {
    --inset: var(--sp-6);
  }

  .ability-hotkeys {
    position: absolute;
    top: var(--inset);
    left: var(--inset);
    display: flex;
    gap: var(--sp-2);
  }
  .hotkey-button {
    --radii: var(--br);
    --bg: var(--shade10);
    position: relative;
    padding: var(--sp-0_5);
    background: var(--bg);
    border-radius: var(--radii);
    cursor: pointer;
    box-shadow: 0 0 16px 5px var(--shade10);
    text-shadow: 0 2px 7px var(--shade10);

    @container content-card (inline-size <= 600px) {
      width: var(--sp-8);
      height: var(--sp-8);
    }

    &.selected-hotkey {
      --bg: var(--ap);
    }
    img {
      max-width: 100%;
      aspect-ratio: 1;
      border-radius: var(--br-sm);
      background: var(--shade5);
    }
    .hotkey {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--sp-5);
      background: red;
      background: var(--bg);
      border-top-left-radius: var(--radii);
      border-bottom-right-radius: var(--radii);

      @container content-card (inline-size <= 600px) {
        top: -0.5rem;
        left: -0.5rem;
      }
    }
  }

  .video-container,
  video {
    border-radius: var(--br-lg);
  }
  .video-container {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    mask-image: linear-gradient(to top, transparent, black 45%);

    video {
      max-width: 100%;
      display: block;
      height: auto;
      /* transform: scale(1.15) translateY(-20px);
      transform-origin: 25% 25%; */

      @container content-card (inline-size <= 600px) {
        transform: scale(1.15) translateY(0);
      }
    }
  }

  .ability-description {
    position: relative;
    padding: var(--inset);
    max-width: 84ch;
    margin-top: -13ch;

    @container content-card (inline-size <= 600px) {
      margin-top: -7ch;
    }
  }
`;
