import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import AbilityImg from "@/game-lol/components/AbilityImg.jsx";
import { SKILL_HOTKEYS } from "@/game-lol/constants/constants.mjs";

const ChampionAbility = styled("div")`
  position: relative;
`;

const FallbackAbility = styled("div")`
  width: var(--sp-6);
  height: var(--sp-6);
  background: var(--shade7);
  border-radius: var(--br-sm);
`;

const AbilityKey = styled("div")`
  background: var(--shade10);
  border-radius: 4px 0 4px 0;
  bottom: 0;
  height: var(--sp-4);
  font-size: 0.875rem;
  line-height: 19px;
  position: absolute;
  right: 0;
  text-align: center;
  width: var(--sp-4);
  pointer-events: none;
`;

const ChampionAbilities = ({ champion }) => {
  const { t } = useTranslation();

  const size = "var(--sp-6)";

  if (!champion?.spells) {
    const style = { width: size, height: size };
    return (
      <div className="flex gap-sp-1">
        <FallbackAbility style={style} />
        <FallbackAbility style={style} />
        <FallbackAbility style={style} />
        <FallbackAbility style={style} />
        <FallbackAbility style={style} />
      </div>
    );
  }

  return (
    <div className="flex gap-sp-1">
      {(champion?.spells || []).map((_, abilityIndex) => (
        <ChampionAbility key={abilityIndex}>
          <AbilityImg
            champion={champion}
            abilityIndex={abilityIndex}
            data-place={`bottom"`}
            size={size}
          />
          <AbilityKey>
            {abilityIndex === 0
              ? t("lol:championAbility.keyP", "P")
              : SKILL_HOTKEYS[abilityIndex - 1]}
          </AbilityKey>
        </ChampionAbility>
      ))}
    </div>
  );
};

export default ChampionAbilities;
