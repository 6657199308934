import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import AbilitiesSmall from "@/game-lol/components/AbilitiesSmall.jsx";
import {
  BuildDetails,
  Container,
  ItemSeparatorContainer,
  RunePageOptions,
} from "@/game-lol/components/ChampionBuilds.style.jsx";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import ItemBuild from "@/game-lol/components/ItemBuild.jsx";
import ItemImg from "@/game-lol/components/ItemImg.jsx";
import ItemsContainer from "@/game-lol/components/ItemsContainer.jsx";
import RuneImg from "@/game-lol/components/RuneImg.jsx";
import RuneTree from "@/game-lol/components/RuneTree.jsx";
import SummonerSpells from "@/game-lol/components/SummonerSpells.jsx";
import {
  BOOTS,
  BUILD_SORT,
  MAX_BUILDS_SHOWN,
  POTIONS,
  TRINKETS,
} from "@/game-lol/constants/builds-constants.mjs";
import {
  BUILD_TYPES,
  ROLE_SYMBOL_TO_STR,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import {
  getStaticData,
  getWinRateColor,
  isRoleQueue,
  mapRoleToSymbol,
} from "@/game-lol/utils/util.mjs";
import {
  formatBuildPlaystyle,
  orderByType,
} from "@/game-lol/utils/util-builds.mjs";
import ChevronRight from "@/inline-assets/chevron-right.svg";
import { classNames } from "@/util/class-names.mjs";
import { devError } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const BUILD_SORT_TYPE = BUILD_SORT.common;

// Text
const TEXT_WINRATE_GAMES = [
  "common:stats.winRateParensGames",
  "{{winrate}} Win Rate ({{games}} Games)",
];
const VS = ["common:vs", "vs"];
const RUNES = ["lol:runes.runes", "Runes"];
const CHAMPION_RUNES = [
  "lol:championBuildNameRunes",
  "{{champion}} {{buildName}} Runes",
];
const CHAMPION_CORE_BUILD_ORDER = [
  "lol:championData.coreChampionItemBuildOrder",
  "Core {{champion}} Item Build Order",
];
const CHAMPION_CORE_BUILD_PATH = [
  "lol:championData.coreChampionItemBuildPath",
  "Core {{champion}} Item Build Path",
];
const SITUATIONAL = ["common:situational", "Situational"];
const CHAMPION_SKILL_ORDER = [
  "lol:builds.championSkillOrder",
  "{{champion}} Skill Order",
];

const ChampionBuilds = ({
  championId,
  champion,
  matchupChampionId,
  championRole,
  queue,
  region,
}) => {
  const { t } = useTranslation();
  const {
    lol: { championBuilds = {} },
  } = useSnapshot(readState);

  const [selectedBuild, setSelectedBuild] = useState("");

  const itemsStaticData = getStaticData("items");

  const buildsData =
    championBuilds[championId]?.[region]?.[queue]?.[championRole];

  const queueSymbol = QueueSymbol(queue);
  const role = !isRoleQueue(queueSymbol)
    ? null
    : ROLE_SYMBOL_TO_STR[mapRoleToSymbol(championRole)]?.gql;

  const builds = useMemo(() => {
    const playstyleData = buildsData?.[null];
    const matchupData = buildsData?.[matchupChampionId];

    const data = matchupChampionId ? matchupData : playstyleData;

    if (!data?.length) return [];

    return data
      .map((build, index) => {
        let result;
        try {
          result = formatBuildPlaystyle({
            build: {
              key: `${championId}:${BUILD_TYPES.general}:${index}:${queue}:${role}`,
              data: build,
            },
            type: BUILD_SORT_TYPE,
            role,
            itemsData: itemsStaticData,
          });
        } catch (error) {
          devError(`FORMAT BUILD FAILED`, error);
          return false;
        }
        return result;
      })
      .filter(Boolean)
      .sort((a, b) => orderByType(a, b, BUILD_SORT_TYPE))
      .slice(0, MAX_BUILDS_SHOWN);
  }, [buildsData, itemsStaticData, championId, matchupChampionId, queue, role]);

  useEffect(() => {
    if (championId || championRole) {
      setSelectedBuild("");
    }
  }, [championId, championRole]);

  if (!buildsData) {
    return <Card loading style={{ height: 728 }} />;
  } else if (!builds.length) {
    return (
      <Card style={{ height: 240, display: "grid", placeContent: "center" }}>
        <span className="type-page-header">
          {t("lol:builds.noBuildsFound", "No Builds Found")}
        </span>
      </Card>
    );
  }

  const build =
    builds.find((build) => build.key === selectedBuild) || builds[0];

  return (
    <Container padding="0">
      <header className="tabs-header">
        <nav className="tabs-list">
          {builds.map((build, index) => {
            const isSelected =
              selectedBuild === build.key || (!selectedBuild && index === 0);
            return (
              <button
                key={build.key}
                onClick={() => {
                  setSelectedBuild(build.key);
                  sendInteractionEvent("champion-page-select-build");
                }}
                {...classNames("tabs-tab", isSelected && "active")}
              >
                <div className="contents">
                  {build.matchupChampionId ? (
                    <>
                      <span className="type-caption">{t(...VS)}</span>
                      <ChampionImg championId={build.matchupChampionId} />
                    </>
                  ) : null}
                  <div className="items">
                    {build.items_completed
                      .slice(0, 3)
                      .filter((item) => !BOOTS[item])
                      .map((itemId) => {
                        return (
                          <ItemImg
                            key={itemId}
                            size={1.75}
                            itemId={itemId}
                            noTooltip
                          />
                        );
                      })}
                  </div>
                  <div style={{ marginInlineEnd: "var(--sp-4)" }}>
                    <span className="title type-callout--bold">
                      {build.misc.playstyleTitle}
                    </span>
                    <span className="games type-caption">
                      {t("lol:countGame_plural", "{{count}} Games", {
                        count: build.games.toLocaleString(getLocale()),
                      })}
                    </span>
                  </div>
                  <span
                    className="winrate type-caption--bold"
                    style={{
                      "--wr-color": getWinRateColor(
                        (build.wins / build.games) * 100,
                      ),
                    }}
                  >
                    {(build.wins / build.games).toLocaleString(getLocale(), {
                      style: "percent",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
              </button>
            );
          })}
        </nav>
      </header>
      <SelectedBuild
        champion={champion}
        championId={championId}
        queue={queue}
        build={build}
      />
    </Container>
  );
};

export default memo(ChampionBuilds);

const SelectedBuild = ({
  champion,
  // championId,
  build = {},
  queue,
}) => {
  const [selectedRunePage, setSelectedRunePage] = useState(0);
  const { t } = useTranslation();
  const runePage = build.runePages?.[selectedRunePage];

  useEffect(() => {
    if (!runePage) setSelectedRunePage(0);
  }, [runePage]);

  const patch = build?.misc?.patch;

  const starting = build.items_starting || [];
  const order = build.items_order || [];
  const buildOrder = [
    ...starting.filter((id) => !POTIONS[id] && !TRINKETS[id]),
    ...order.filter((id) => !build.items_starting.includes(id)),
  ];

  const coreItemsStats = build.stats?.coreItems?.[build.items_core.join(",")];

  return (
    <BuildDetails>
      <div className="build-container">
        {/* Runes */}
        <div className="flex column align-center build-section">
          <h3 className="type-subtitle--bold build-title center">
            {IS_APP
              ? t(...RUNES)
              : t(...CHAMPION_RUNES, {
                  champion: champion.name,
                  buildName: build.misc.playstyleTitle,
                })}
          </h3>
          {build?.misc?.patch && (
            <span className="type-caption--semi build-subtitle center">
              {t("lol:buildPatchVersion", "Build Patch {{version}}", {
                version: build.misc.patch,
              })}
            </span>
          )}

          <div className={RunePageOptions()}>
            {build.runePages.map((runePage, i) => {
              return (
                <button
                  key={i}
                  onClick={() => {
                    setSelectedRunePage(i);
                    sendInteractionEvent("champion-page-select-rune-page");
                  }}
                  data-selected={selectedRunePage === i}
                  style={{
                    "--wr-color": getWinRateColor(
                      (runePage.wins / runePage.games) * 100,
                    ),
                  }}
                >
                  <div className="top">
                    <div className="runes">
                      <RuneImg
                        currRune={{ id: runePage.keystoneId }}
                        isActive={true}
                        className="keystone"
                      />
                      <div className="secondary-tree">
                        <RuneImg
                          currRune={{ id: runePage.subStyleId }}
                          isActive={true}
                        />
                      </div>
                    </div>
                    <span className="winrate type-caption--semi">
                      {(runePage.wins / runePage.games).toLocaleString(
                        getLocale(),
                        {
                          style: "percent",
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        },
                      )}
                    </span>
                  </div>
                  <span className="games type-caption">
                    {t("lol:countGame_plural", "{{count}} Games", {
                      count: runePage.games.toLocaleString(getLocale()),
                    })}
                  </span>
                </button>
              );
            })}
          </div>

          <RuneTree
            patch={patch}
            runesList={runePage?.fullPage}
            shardsList={runePage?.shards}
            runesStats={build.stats?.runes}
            buildGames={build.games}
          />
        </div>

        {/* Items */}
        <div className="flex column gap-sp-4">
          {/* Final full items */}
          <div className="build-section">
            <h3 className="type-subtitle--bold build-title">
              {t(...CHAMPION_CORE_BUILD_ORDER, { champion: champion.name })}
            </h3>
            {coreItemsStats?.games && (
              <span className="type-caption--semi build-subtitle">
                {t(...TEXT_WINRATE_GAMES, {
                  games: coreItemsStats.games.toLocaleString(getLocale()),
                  winrate: (
                    coreItemsStats.wins / coreItemsStats.games
                  ).toLocaleString(getLocale(), {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }),
                })}
              </span>
            )}
            <div className="desktop">
              <ItemBuild
                starting={build.items_starting}
                items={build.items_completed}
                size={48}
                queue={queue}
                noComponents
              />
            </div>

            <div className="mobile">
              <ItemBuild
                starting={build.items_starting}
                items={build.items_completed}
                size={20}
                noTag
                noComponents
              />
            </div>
          </div>

          {/* Build order */}
          <div className="build-section">
            <h3 className="type-subtitle--bold build-title">
              {t(...CHAMPION_CORE_BUILD_PATH, { champion: champion.name })}
            </h3>

            <ItemsContainer
              hideTitle
              itemOrder
              patch={patch}
              size={1.75}
              items={buildOrder}
              renderSeparator={() => {
                return (
                  <ItemSeparatorContainer>
                    <ChevronRight />
                  </ItemSeparatorContainer>
                );
              }}
            />
          </div>

          {/* Situational items */}
          {build.items_situational.length ? (
            <div className="build-section">
              <h3 className="type-subtitle--bold build-title">
                {t(...SITUATIONAL)}
              </h3>
              <div className="flex wrap gap-sp-1" suppressHydrationWarning>
                {build.items_situational.map((id) => (
                  <ItemImg key={id} size={1.75} itemId={id} />
                ))}
              </div>
            </div>
          ) : null}

          {/* Summoner spells */}
          <div className="build-section">
            <h3 className="type-subtitle--bold build-title">
              {t("lol:championData.summonerSpells", "Summoner Spells")}
            </h3>
            {/* {summonerSpellStats?.games && (
              <span className="type-caption--semi build-subtitle">
                {t(...TEXT_WINRATE_GAMES, {
                  games: summonerSpellStats.games.toLocaleString(getLocale()),
                  winrate: (
                    summonerSpellStats.wins / summonerSpellStats.games
                  ).toLocaleString(getLocale(), {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }),
                })}
              </span>
            )} */}
            <SummonerSpells
              spells={build.summonerSpellPages}
              patch={patch}
              size={1.75}
            />
          </div>

          {/* <div>
            <p className="type-subtitle--bold build-title">
              {t("lol:abilities.damageBreakdown", "Damage Breakdown")}
            </p>
            <DamageBreakdown teamStats={[championStats]} hiddenThreshold={12} />
          </div> */}
        </div>
      </div>

      <div className="ability-order build-section">
        <h3 className="type-subtitle--bold build-title">
          <a href={`/${lolRefs.lolChampionPrefix}/${champion?.key}/abilities`}>
            {t(...CHAMPION_SKILL_ORDER, {
              champion: champion.name,
            })}
          </a>
        </h3>

        {build.stats?.skills?.games && (
          <span className="type-caption--semi build-subtitle">
            {t(...TEXT_WINRATE_GAMES, {
              games: build.stats.skills.games.toLocaleString(getLocale()),
              winrate: (
                build.stats.skills.wins / build.stats.skills.games
              ).toLocaleString(getLocale(), {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
            })}
          </span>
        )}
        <AbilitiesSmall
          champion={champion}
          skills={build.skills}
          showSkillOrder
        />
      </div>
    </BuildDetails>
  );
};
