import React from "react";
import { styled } from "goober";

import LoadingSpinner from "@/inline-assets/loading-spinner.svg";
import {
  FlexContainer,
  LoadingContainer,
  TabContainer,
} from "@/shared/InfiniteTable.style.jsx";
import { useIsLoaded } from "@/util/router-hooks.mjs";

type TableLayoutProps = {
  filters?: React.ReactNode;
  table?: React.ReactNode;
  className?: string;
};

export const TableContainer = styled(FlexContainer)<{ $opacity?: number }>`
  .infinite-table .header,
  .infinite-table .row {
    .column:first-of-type {
      padding-inline-start: var(--sp-6);
    }

    .column:last-of-type {
      padding-inline-end: var(--sp-6);
    }
  }
`;

export default function TableLayout({
  filters,
  table,
  className,
}: TableLayoutProps) {
  const isLoaded = useIsLoaded();

  return (
    <TabContainer className={className}>
      {filters}

      <TableContainer $opacity={isLoaded ? 1 : 0.5}>{table}</TableContainer>

      {!isLoaded && (
        <LoadingContainer className="loading-container">
          <LoadingSpinner />
        </LoadingContainer>
      )}
    </TabContainer>
  );
}
