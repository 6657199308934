import React from "react";
import { useTranslation } from "react-i18next";

import { SummonerSpellOptions } from "@/game-lol/components/ChampionBuilds.style.jsx";
import SpellImg from "@/game-lol/components/SpellImg.jsx";
import { getWinRateColor } from "@/game-lol/utils/util.mjs";
import { summonerSpellPreference } from "@/game-lol/utils/util-builds.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import SuppressHydrationWarnings from "@/util/SuppressHydrationWarnings.jsx";

const SummonerSpells = ({ size = 1.75, spells = [] }) => {
  const { t } = useTranslation();

  return (
    <div className={SummonerSpellOptions()}>
      {spells.map((spellsSet, i) => (
        <div
          key={i}
          className="spells-set"
          style={{
            "--wr-color": getWinRateColor(
              (spellsSet.wins / spellsSet.games) * 100,
            ),
          }}
        >
          <div className="imgs">
            <SuppressHydrationWarnings>
              {summonerSpellPreference(spellsSet.summonerSpellIds, 0).map(
                (spellId) => (
                  <SpellImg key={spellId} spellId={spellId} size={size} />
                ),
              )}
            </SuppressHydrationWarnings>
          </div>
          <span className="games type-caption">
            {t("lol:countGame_plural", "{{count}} Games", {
              count: spellsSet.games.toLocaleString(getLocale()),
            })}
          </span>
          <span className="winrate type-caption--semi">
            {(spellsSet.wins / spellsSet.games).toLocaleString(getLocale(), {
              style: "percent",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </span>
        </div>
      ))}
    </div>
  );
};

export default SummonerSpells;
