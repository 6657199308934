import { css } from "goober";

export const Container = () => css`
  container-type: inline-size;
  container-name: matchup-columns;

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--sp-4);

    @container matchup-columns (inline-size <= 600px) {
      grid-template-columns: unset;
    }
  }
`;
