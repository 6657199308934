import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import RuneImg from "@/game-lol/components/RuneImg.jsx";
import SkillOrder from "@/game-lol/components/SkillOrder.jsx";
import { SKILL_HOTKEYS } from "@/game-lol/constants/constants.mjs";
import Static from "@/game-lol/utils/static.mjs";
import {
  aggregateMatches,
  getCurrentPatchForStaticData,
  getStaticData,
  getWinRateColor,
} from "@/game-lol/utils/util.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";

const SummaryCaption = styled("span")`
  color: var(--shade2);
  margin-bottom: var(--sp-2);
`;

const SummaryContainer = styled("div")`
  display: flex;
  gap: var(--sp-1);
  justify-content: space-between;
`;

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Images = styled("div")`
  display: flex;
  gap: var(--sp-0_5);
`;

function getKeystoneTree(keytone = "", perks = []) {
  const treeName = "";

  // Loop through trees
  for (const tree of perks) {
    // Loop through rows
    for (const runeRow of tree.slots) {
      // Loop through runes in row
      for (const rune of runeRow.runes) {
        if (rune.id === Number(keytone)) {
          return tree.key;
        }
      }
    }
  }

  return treeName;
}

const ProBuildSummary = ({ matches }) => {
  const { t } = useTranslation();
  const patch = getCurrentPatchForStaticData();
  const perks = getStaticData("runes");
  const itemsStaticData = getStaticData("items", patch);

  const aggregateData = useMemo(() => {
    return aggregateMatches(matches, itemsStaticData);
  }, [matches, itemsStaticData]);

  if (!matches || !itemsStaticData) return null;

  return (
    <SummaryContainer>
      <Section>
        <SummaryCaption className="type-overline">
          {t("lol:winRate", "Win Rate")}
        </SummaryCaption>
        <h6
          className="type-h6"
          style={{
            color: getWinRateColor(aggregateData.winRate),
            textAlign: "center",
          }}
        >
          {getLocaleString(aggregateData.winRate, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}
          %
        </h6>
      </Section>
      <Section>
        <SummaryCaption className="type-overline">
          {t("lol:runes.keystones", "Keystones")}
        </SummaryCaption>
        <Images>
          {aggregateData.keystones.slice(0, 3).map((rune) => (
            <RuneImg
              isActive
              key={rune}
              size={2}
              currRune={{ id: rune }}
              treeKey={getKeystoneTree(rune, perks).toLowerCase()}
            />
          ))}
        </Images>
      </Section>
      <Section>
        <SummaryCaption className="type-overline">
          {t("lol:builds.skillOrder", "Skill Order")}
        </SummaryCaption>
        <SkillOrder
          order={aggregateData.skillOrders[0]
            .split(":")
            .map((key) => SKILL_HOTKEYS.findIndex((s) => s === key) + 1)}
        />
      </Section>
      <Section>
        <SummaryCaption className="type-overline">
          {t("lol:championData.starting", "Starting")}
        </SummaryCaption>
        <Images>
          {aggregateData.starters.slice(0, 3).map((item) => {
            return (
              <img
                key={item.id}
                src={Static.getItemImage(item.id)}
                width="32"
                height="32"
              />
            );
          })}
        </Images>
      </Section>
      <Section>
        <SummaryCaption className="type-overline">
          {t("lol:championData.commonItems", "Common Items")}
        </SummaryCaption>
        <Images>
          {aggregateData.items.slice(0, 6).map((item) => {
            return (
              <img
                key={item}
                src={Static.getItemImage(item)}
                width="32"
                height="32"
              />
            );
          })}
        </Images>
      </Section>
    </SummaryContainer>
  );
};

export default ProBuildSummary;
