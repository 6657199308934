import React from "react";
import { styled } from "goober";

import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import Close from "@/inline-assets/close.svg";

const ChampionImageContainer = styled("div")`
  position: relative;

  .champion-remove-icon {
    height: var(--sp-8);
    width: var(--sp-8);
    color: var(--shade0);
    left: var(--sp-6);
    position: absolute;
    transition: var(--transition);
    transform: scale(0);
    top: var(--sp-6);
  }

  &:hover {
    cursor: pointer;

    .champion-img {
      opacity: 0.4;
    }

    .champion-remove-icon {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const ChampionHeaderImage = ({ championId, isMatchupChampion, size }) => {
  return (
    <ChampionImageContainer>
      <ChampionImg
        championId={championId}
        disabled={isMatchupChampion}
        size={size}
      />
      {isMatchupChampion && <Close className="champion-remove-icon" />}
    </ChampionImageContainer>
  );
};

export default ChampionHeaderImage;
