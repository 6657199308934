import React from "react";

import ChampionImg from "@/game-lol/components/ChampionImg.jsx";

function ChampionMatchupImg({ championId, winRateColor }) {
  return (
    <ChampionImg
      id={championId}
      size={28}
      style={{
        boxShadow: `0 0 0 1px var(--shade8), 0 0 0 3px ${winRateColor}`,
      }}
    />
  );
}

export default ChampionMatchupImg;
