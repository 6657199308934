import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { Container } from "@/game-lol/components/ChampionOverviewTrends.style.jsx";
import { StatInfo } from "@/game-lol/components/Trends.style.jsx";
import {
  QUEUE_SYMBOLS,
  ROLE_SYMBOL_TO_STR,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import {
  getSearchParamsForChampion,
  mapRoleToSymbol,
} from "@/game-lol/utils/util.mjs";
import SimpleLine from "@/shared/SimpleLineChart.jsx";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ChampionOverviewTrends = ({
  championId,
  champion,
  filters,
  queue,
  patch,
}) => {
  const { t } = useTranslation();
  const {
    lol: { championStatsTrends },
  } = useSnapshot(readState);
  const urlParams = getSearchParamsForChampion(filters);
  const championTrends =
    championStatsTrends?.[championId]?.[urlParams.toString()];

  const roleText =
    filters.role &&
    ROLE_SYMBOL_TO_STR[mapRoleToSymbol(filters.role)]?.label &&
    t(...ROLE_SYMBOL_TO_STR[mapRoleToSymbol(filters.role)].label);

  if (!championTrends) {
    return <Card loading style={{ height: 208 }} />;
  }

  const isARAM = queue === getQueueDetails(QUEUE_SYMBOLS.aram).gql;

  const trendsList = [
    ...(Array.isArray(championTrends) ? championTrends : []),
  ].reverse();

  return (
    <Card
      padding="var(--sp-5)"
      className={Container()}
      titleTag="h2"
      title={
        champion?.name && !isARAM
          ? t(
              "lol:championsPage.championsTrendsRolePatch",
              "{{champion}}'s Trends for {{role}}, Platinum+ for Patch {{patch}}",
              {
                champion: champion?.name,
                role: roleText,
                patch,
              },
            )
          : isARAM
            ? t(
                "lol:championsPage.championsTrendsARAM",
                "{{champion}}'s Trends for ARAM",
                {
                  champion: champion?.name,
                },
              )
            : t("lol:championsPage.championsTrends", "Champion's Trends")
      }
      titleLink={`/${lolRefs.lolChampionPrefix}/${champion?.key}/trends`}
      subtitle={
        champion?.name
          ? t(
              "lol:championsPage.championNameTrendsSubtitle",
              "Stay ahead of the curve with the latest win rates, pick rates, and ban rates for {{champion}}. Our Trends section offers a deep dive into how {{champion}} is performing in the current meta, with data from the last five patches.",
              { champion: champion?.name },
            )
          : t(
              "lol:championsPage.trendsSubtitle",
              "Stay ahead of the curve with the latest win rates, pick rates, and ban rates. Our Trends section offers a deep dive into how champions are performing in the current meta, with data from the last five patches.",
            )
      }
    >
      <>
        <Trend
          title={t("lol:winRate", "Win Rate")}
          points={trendsList.map((stats) => ({
            patch: stats.patch,
            value: stats.wins / (stats.games || 1),
          }))}
          valueLabel={t("lol:winRate", "Win Rate")}
        />
        <Trend
          title={t("lol:pickRate", "Pick Rate")}
          points={trendsList.map((stats) => ({
            patch: stats.patch,
            value: stats.pickRate,
          }))}
          valueLabel={t("lol:pickRate", "Pick Rate")}
        />
        {queue !== getQueueDetails(QUEUE_SYMBOLS.aram).gql ? (
          <Trend
            title={t("lol:banRate", "Ban Rate")}
            points={trendsList.map((stats) => ({
              patch: stats.patch,
              value: stats.banRate,
            }))}
            valueLabel={t("lol:banRate", "Ban Rate")}
          />
        ) : null}
      </>
    </Card>
  );
};

export default ChampionOverviewTrends;

const chartConfig = {
  margin: { left: 8, right: 5, top: 5, bottom: 5 },
  xAxisConf: { visible: false },
  yAxisConf: { visible: false },
};

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

function Trend({ title, points, hideGraph }) {
  const { t } = useTranslation();
  const last = (points[points.length - 1] || {})?.value;
  const secondLast = (points[points.length - 2] || {})?.value;
  const trend = points.map((p) => ({
    x: p.patch,
    y: p.value,
  }));

  const value = getLocaleString(last, percentOptions);
  const delta =
    last && secondLast && getLocaleString(last - secondLast, percentOptions);
  const isPositive =
    points[points.length - 1]?.value >= points[points.length - 2]?.value;

  return (
    <div className="stat">
      <div>
        <span className="type-title--bold stat-title">{title}</span>
        <StatInfo
          value={value}
          delta={delta}
          caption={t("lol:championsPage.vsLastPatch", "vs last patch")}
        />
      </div>
      {hideGraph ? null : (
        <SimpleLine
          showGridLines={false}
          margin={chartConfig.margin}
          data={trend}
          xField="x"
          yField="y"
          width={120}
          height={44}
          xAxisConf={chartConfig.xAxisConf}
          yAxisConf={chartConfig.yAxisConf}
          color={isPositive ? "var(--turq)" : "var(--red)"}
          circleRadius={5}
        />
      )}
    </div>
  );
}
