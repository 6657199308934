import { css } from "goober";

export const Container = () => css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: var(--sp-3);
  padding: var(--sp-6);

  a {
    display: grid;
    place-items: center;
    gap: var(--sp-4);
    padding: var(--sp-3) var(--sp-6);
    background: var(--shade5-15);
    text-align: center;
    border-radius: var(--br);
    box-shadow: 0 0 0 1px var(--card-inset-border);
    transition:
      background var(--transition),
      box-shadow var(--transition);

    &:hover {
      background: var(--shade3-25);
      box-shadow: 0 0 0 1px var(--shade0-15);
    }
  }
`;
