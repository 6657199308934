import React from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { Container } from "@/game-lol/components/ChampionMatchupColumns.style.jsx";
import ChampionMatchupImg from "@/game-lol/components/ChampionMatchupImg.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import {
  commonMatchups,
  getLaneWinRateColor,
  getStaticData,
  getWinRateColor,
} from "@/game-lol/utils/util.mjs";
import DataTable from "@/shared/DataTable";
import { getLocale } from "@/util/i18n-helper.mjs";

function MatchupColumns({ champion, matchups }) {
  const { t } = useTranslation();
  const champions = getStaticData("champions");

  if (!matchups) {
    return (
      <div className={Container()}>
        <div className="columns">
          <Card loading style={{ height: 600 }} />
          <Card loading style={{ height: 600 }} />
        </div>
      </div>
    );
  }

  const viableMatchups = commonMatchups(matchups);
  const totalMatchupsGames = matchups.reduce((acc, curr) => {
    return acc + (curr.games || 0);
  }, 0);

  const matchupsData = viableMatchups.map((matchup) => {
    const { opponentChampionId, wins, laneWins, games } = matchup;
    const championKey = champions?.keys?.[opponentChampionId];
    const champion = champions?.[championKey];
    const championName = champion?.name;

    const gameWinrate = 1 - wins / games;
    const laneWinrate = 1 - laneWins / games;
    const matchupRate = games / totalMatchupsGames;

    return {
      championKey,
      championName,
      opponentChampionId,
      gameWinrate,
      laneWinrate,
      matchupRate,
      games,
    };
  });

  const laneWinList = matchupsData.sort(
    (a, b) => b.laneWinrate - a.laneWinrate,
  );
  const gameWinList = matchupsData.sort(
    (a, b) => b.gameWinrate - a.gameWinrate,
  );

  return (
    <div className={Container()}>
      <div className="columns">
        <Card
          padding="0"
          title={
            champion?.name
              ? t(
                  "lol:championNamesGameCounters",
                  "{{championName}}'s Game Counters",
                  {
                    championName: champion.name,
                  },
                )
              : t("common:gameCounters", "Game Counters")
          }
          subtitle={
            champion?.name
              ? t(
                  "lol:championNameGameCountersSubtitle",
                  "The below champions are especially good against {{championName}} specificially when it comes to winning the game. The win rates shown is {{championName}}'s win rate against these champions.",
                  {
                    championName: champion.name,
                  },
                )
              : t(
                  "lol:gameCountersSubtitle",
                  "The below champions are especially good at winning the game against this champion.",
                )
          }
        >
          <DataTable
            borderless
            sortCol={1} // game win rate
            sortDir="DESC"
            cols={[
              {
                display: t("lol:champion", "Champion"),
                align: "left",
                primary: true,
              },
              {
                display: t("lol:stats.gameWinRate", "Game Win Rate"),
                isStat: true,
              },
              {
                display: t("common:matches", "Matches"),
                isStat: true,
              },
            ]}
            rows={gameWinList.map((matchup) => {
              const {
                championKey,
                opponentChampionId,
                championName,
                gameWinrate,
                games,
              } = matchup;

              // We want to display the win rate of the champion whose page we are on
              const displayGameWinrate = 1 - gameWinrate;
              const winRateColor = getWinRateColor(displayGameWinrate * 100);

              return [
                {
                  display: (
                    <>
                      <ChampionMatchupImg
                        championId={opponentChampionId}
                        winRateColor={winRateColor}
                      />
                      <span className="name-string">
                        {championName || opponentChampionId}
                      </span>
                    </>
                  ),
                  value: championName,
                  link: `/${lolRefs.lolChampionPrefix}/${championKey}`,
                  style: {
                    gap: "var(--sp-3)",
                  },
                },
                {
                  display: displayGameWinrate.toLocaleString(getLocale(), {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }),
                  value: gameWinrate,
                  // pillsColor: winRateColor,
                },
                {
                  display: games.toLocaleString(getLocale()),
                  value: games,
                },
              ];
            })}
          />
        </Card>
        <Card
          padding="0"
          title={
            champion?.name
              ? t(
                  "lol:championNamesLaneCounters",
                  "{{championName}}'s Lane Counters",
                  {
                    championName: champion.name,
                  },
                )
              : t("common:gameCounters", "Game Counters")
          }
          subtitle={
            champion?.name
              ? t(
                  "lol:championNameLaneCountersSubtitle",
                  "The below champions are especially good against {{championName}} during lane phase (gold difference @14 mins). The win rates shown is {{championName}}'s win rate against these champions.",
                  {
                    championName: champion.name,
                  },
                )
              : t(
                  "lol:laneCountersSubtitle",
                  "The below champions are especially good at winning lane against this champion.",
                )
          }
        >
          <DataTable
            borderless
            sortCol={1} // lane win rate
            sortDir="DESC"
            cols={[
              {
                display: t("lol:champion", "Champion"),
                align: "left",
                primary: true,
              },
              {
                display: t("lol:stats.laneWinRate", "Lane Win Rate"),
                isStat: true,
              },
              {
                display: t("common:matches", "Matches"),
                isStat: true,
              },
            ]}
            rows={laneWinList.map((matchup) => {
              const {
                championKey,
                opponentChampionId,
                championName,
                laneWinrate,
                games,
              } = matchup;

              // We want to display the win rate of the champion whose page we are on
              const displayedLaneWinrate = 1 - laneWinrate;
              const winRateColor = getLaneWinRateColor(
                displayedLaneWinrate * 100,
              );

              return [
                {
                  display: (
                    <>
                      <ChampionMatchupImg
                        championId={opponentChampionId}
                        winRateColor={winRateColor}
                      />
                      <span className="name-string">
                        {championName || opponentChampionId}
                      </span>
                    </>
                  ),
                  value: championName,
                  link: `/${lolRefs.lolChampionPrefix}/${championKey}`,
                  style: { gap: "var(--sp-3)" },
                },
                {
                  display: displayedLaneWinrate.toLocaleString(getLocale(), {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }),
                  value: laneWinrate,
                  // pillsColor: winRateColor,
                },
                {
                  display: games.toLocaleString(getLocale()),
                  value: games,
                },
              ];
            })}
          />
        </Card>
      </div>
    </div>
  );
}

export default MatchupColumns;
